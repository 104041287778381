<template>
  <!-- <v-layout max-width class="flex-column white"> -->

  <v-layout class="flex-column">
    <!-- style="border-bottom:1px solid #e8e8e8;" -->
    <div class="flex-y-center flex-justify-between py-2 px-4 mb-3 white ">
      <div class="">
        <v-btn dark small color="#00bea9" depressed min-width="32" style="height:34px;" @click="addShow">
          <v-icon size="20">mdi-plus</v-icon>添加
        </v-btn>
      </div>
      <div class=" flex-y-center">
        <div class="flex-y-center mr-1" style="width:120px;">
          <form-item
            v-model="search.baselineName"
            class="mr-0"
            placeholder="访视基线名称"
            type="text"
          />
        </div>
        <v-btn dark small color="primary" depressed min-width="34" style="height:34px; padding:0;" @click="initList()">
          <v-icon size="18">mdi-magnify</v-icon>
        </v-btn>
        <!-- <v-btn depressed color="primary">搜索</v-btn> -->
      </div>

    </div>
    <div class=" white">
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="search.pageSize"
        class="pb-5 max-width pa-4"
        hide-default-footer
        item-key="id"
        no-data-text="暂无查询数据"
        :loading="listLoading"
      >
        <!-- <template v-slot:item="{expand,item}"> -->
        <template v-slot:item.active="{item}">
          <a class=" mr-2" @click="showEdit(item)">编辑</a>
          <a class=" mr-2" @click="delShow(item.id)">删除</a>
        </template>
      </v-data-table>
      <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end ">
        <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @input="loadList()" @changePageSize="changePageSize" />
      </div>
    </div>
    <!-- 新增 编辑 访视基线 -->
    <v-dialog v-model="dialog.show" width="600">
      <v-card>
        <v-card-title class="font-weight-bold">{{ dialog.id?'编辑访视基线':'新增访视基线' }}</v-card-title>
        <v-divider />
        <v-card-text class=" py-5">
          <div class="flex-1 flex-column overflow-auto mt-7 px-12">
            <div class="mb-6 flex-align-start">
              <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>访视基线：</div>
              <form-item
                v-model="dialog.data.baselineName"
                class="mr-0"
                placeholder="访视基线"
                type="text"
              />
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn width="80" class depressed @click="dialog.show = false">取消</v-btn>
              <v-btn
                width="80"
                class="ml-3"
                color="primary"
                depressed
                @click.stop="submitAdd()"
              >确定</v-btn>

            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 新增 编辑 访视基线 end -->

  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0,
      headers: [
        { text: '名称', value: 'baselineName' },
        { text: '操作', value: 'active' }
      ],
      list: [],
      listLoading: false,
      dialog: {
        show: false,
        type: '',
        data: {
        }
      }
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    initList() {
      this.search.pageNo = 1
      this.loadList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.loadList()
    },
    loadList() {
      this.listLoading = true
      const search = { ...this.search.data }
      search.pageSize = this.search.pageSize
      search.pageNo = this.search.pageNo
      this.$http.get(this.$api.projectVisitBaselineList, { data: search }).then(res => {
        this.listLoading = false
        this.list = res.result.records || []
        this.total = res.result.total || 0
      })
    },
    // 添加访视基线 弹窗
    addShow() {
      this.dialog.show = true
      this.dialog.data = {}
    },
    // 编辑弹窗
    showEdit(item) {
      this.dialog.show = true
      this.dialog.data = { ...item }
    },
    // 删除访视基线
    delShow(id) {
      if (confirm('是否确认删除？')) {
        this.$http.delete(this.$api.projectVisitBaselineDelete, { data: { id: id }}).then(res => {
          this.$message.success(res.message)
          this.loadList()
        })
      }
    },
    // 确认添加 编辑 访视基线
    submitAdd() {
      let url = this.$api.projectVisitBaselineAdd
      let m = 'post'
      if (this.dialog.data.id) {
        url = this.$api.projectVisitBaselineEdit
        m = 'put'
      }
      this.$http[m](url, { data: { ...this.dialog.data }}).then(res => {
        this.$message.success(res.message)
        this.dialog.show = false
        this.dialog.data = {}
        this.loadList()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title{
    width:100px;
    color: #777;
}

</style>
